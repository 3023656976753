<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard class="cards">
        <CCardHeader>
          <CCol col="6" class="styleHeader"
            >{{ $t("message.showOrder") }}
          </CCol>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-bordered">
                <tr>
                  <th>{{ $t("message.orderNo") }}</th>
                  <td>{{ order.order_no }}</td>
                </tr>
                <tr>
                  <th>{{ $t("message.orderType") }}</th>
                  <td v-if="order.order_type.name == 'Sales'">
                    {{ $t("message.sales") }}
                  </td>
                  <td v-else>{{ $t("message.purchase") }}</td>
                </tr>
                <tr>
                  <th>{{ $t("message.total") }}</th>
                  <td>{{ order.total | number }}</td>
                </tr>
                <tr v-if="order.customer">
                  <th>{{ $t("message.orderCustomer") }}</th>
                  <td>{{ order.customer.name }}</td>
                </tr>
                <tr v-if="order.supplier">
                  <th>{{ $t("message.orderSupplier") }}</th>
                  <td>{{ order.supplier.name }}</td>
                </tr>
                <tr v-if="order.office">
                  <th>{{ $t("message.office") }}</th>
                  <td>{{ order.office.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t("message.orderStatus") }}</th>
                  <td v-if="order.status.name == 'panding'">
                    {{ $t("message.pending") }}
                  </td>
                  <td v-else>{{ $t("message.approve") }}</td>
                </tr>
              </table>
            </div>
          </div>
          <hr />
          <h3 class="mb-3">{{ $t("message.products") }}</h3>
          <div class="line"></div>
          <div v-if="order.products[0]" class="row">
            <div class="col-md-10 m-auto">
              <table class="table table-bordered">
                <tr>
                  <th>{{ $t("message.category") }}</th>
                  <th>{{ $t("message.product") }}</th>
                  <th>{{ $t("message.transferNumber") }}</th>
                  <th>{{ $t("message.quantity") }}</th>
                  <th>{{ $t("message.productPrice") }}</th>
                  <th>{{ $t("message.total") }}</th>
                </tr>
                <tr v-for="product in order.products" :key="product.id">
                  <td>{{ product.product.category.name }}</td>
                  <td v-if="$i18n.locale == 'English'">
                    {{ product.product.name }}
                  </td>
                  <td v-if="$i18n.locale == 'العربية'">
                    {{ product.product.name_ar }}
                  </td>
                  <td v-if="product.transfer_number">
                    {{ product.transfer_number }}
                  </td>
                  <td v-else>{{ $t("message.noTransferNum") }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.price | number }}</td>
                  <td>{{ product.total | number }}</td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <CButton color="btn btn-primary" @click="goBack">{{
            $t("message.back")
          }}</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'ShowProduct',
  data () {
    return {
      order: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}orders/${this.$route.params.id}`)
      .then((response) => {
        this.order = response.data.data
      })
  },
  methods: {
    goBack () {
      this.servicesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/orders' })
    }
  }
}
</script>
<style scoped>
th {
  background-color: #643c4f;
  color: #f5f3f3;
}
.title {
  background-color: #a8738c;
  color: #f5f3f3;
  border-radius: 20%;
  padding: 7px;
}
</style>
